<template>
  <div class="page-acceptance" v-if="details.specials&&details.specials.length>0">
    <!-- 日历筛选 -->
    <div
      class="flex items-center justify-center py-2.5 theme-color bg-white mt-0"
      @click="screen_date_show = true"
      style="height: 40px"
    >
      <van-icon
        v-if="screen_date"
        class="theme-color"
        size="20"
        name="notes-o"
      />
      <span v-if="screen_date" class="ml-1 text-sm font-medium">{{
        screen_date
      }}</span>
    </div>
    <div class="place-box flex items-center">
      <template v-for="(item, index) in $route.query.arr.split(',')">
        <div class="place" :key="index">
          {{ item }}
        </div>
        <van-icon name="arrow" :key="index + 'icon'" />
      </template>
    </div>
    <!-- 已选楼盘 -->
    <div class="flex items-center mt-0.5 px-2.5 py-1 bg-white">
      <div class="font-bold flex-1">{{ details.projectName }}</div>
      <!-- <div class="flex items-center flex-col" @click="$router.go(-1)">
                <div class="icon-replay"></div>
                <span class="text-xs font-bold">返回</span>
            </div> -->
    </div>

    <div class="mt-2">
      <score-edit @back="jurisdiction" :details="details"></score-edit>
    </div>
    <div style="width: 100%;height: 100px"></div>
    <!-- 年月筛选 -->
    <van-popup v-model="screen_date_show" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :formatter="formatter"
        @confirm="dateTimeConfirm"
        @cancel="screen_date_show = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { Icon, Picker, Popup, DatetimePicker } from "vant";
import score from "components/score";
import scoreEdit from "components/score_edit_pc";
import { acceptanceDetails, acceptancePutState } from "../../api";
export default {
  name: "Acceptance",
  components: {
    [Icon.name]: Icon,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    // vanImg: Image,
    comScore: score,
    scoreEdit,
  },
  data() {
    return {
      form: {},
      details: {},
      screen_date: "",
      // 年月弹窗开关
      screen_date_show: false,
      // 今天
      currentDate: new Date(),
      months: "",
      authority: 'false'
    };
  },
  created() {
    this.authority = localStorage.getItem('authority')
    this.formData();
    this.acceptanceDetails();
  },
  methods: {
    //是否可以编辑权限验证
    jurisdiction(state) {
      if (state==2) {
          //权限验证
          this.$dialog.confirm({
            title: '提示',
            message: '该验收已经发布，请先撤销发布状态后才可以进行编辑操作',
            confirmButtonText: '撤销发布',
            cancelButtonText: '返回',
            showConfirmButton: this.authority=='false'?false:true
          })
            .then(() => {
              // on confirm
              this.acceptancePutState(1,'撤销发布')
            })
            .catch(() => {
              // on cancel
              this.$router.go(-1)
            })
      }
    },
    //修改验收表状态   撤回
    acceptancePutState(state, text) {
      // this.$dialog.confirm({
      //     title: '提示',
      //     message: '是否确认' + text,
      //   }).then(() => {
          this.$toast.loading({
            message:'',
            forbidClick:true,
            duration:0
          })
          acceptancePutState(this.details.id,state).then((res)=>{
            this.$toast.success('撤回成功')
            this.details.state = state
            // this.$emit('back')
          }).catch(()=>{
            this.$toast.clear()
          })
        // })
    },
    //重置
    reset() {
      localStorage.removeItem("form");
      var s = -(this.$route.query.arr.split(",").length - 1);
      this.$router.go(s);
    },
    acceptanceDetails() {
      this.$toast.loading({
        duration: 0
      })
      acceptanceDetails({
        housesId: this.form.housesId,
        projectId: this.form.projectId,
        specialId: this.form.specialId,
      }).then((res) => {
        var data = res.data.data;
        
        // this.jurisdiction(data.state)

        data.building = data.building?JSON.parse(data.building):{}
        data.imgUrl = data.imgUrl ? data.imgUrl.split(",") : [];
        //判断是否是修改
        if (data.id) {
          var myDate = new Date(data.acceptanceDate.replace(/\-/g, "/"));
          var m = myDate.getMonth() + 1;
          var d = myDate.getDate();
          data.acceptanceDate =
            myDate.getFullYear() +
            "-" +
            (m < 10 ? "0" + m : m) +
            "-" +
            (d < 10 ? "0" + d : d) +
            " 00:00:00";
          this.screen_date =
            myDate.getFullYear() + " 年 " + m + " 月 " + d + " 日";
        } else {
          var myDate = new Date();
          var m = myDate.getMonth() + 1;
          var d = myDate.getDate();
          data.acceptanceDate =
            myDate.getFullYear() +
            "-" +
            (m < 10 ? "0" + m : m) +
            "-" +
            (d < 10 ? "0" + d : d) +
            " 00:00:00";
          this.screen_date =
            myDate.getFullYear() + " 年 " + m + " 月 " + d + " 日";
        }

        
        var key = data.projectId + '-' + data.specialId + '-' + data.housesId
        
        
        //判断是否有实时保存
        
        if (localStorage.getItem(key)) {
          var draft = JSON.parse(localStorage.getItem(key))
          //版本号不一致清空缓存
          if (draft.version!=data.version) {
            localStorage.removeItem(key)
          } else {
            data.additions = draft.additions
            data.contents = draft.contents
            data.evaluation = draft.evaluation
            data.imgUrl = draft.imgUrl ? draft.imgUrl.split(",") : [];
          }
        }
        
        var specials = data.specials;
        for (var item of specials) {
          var obj = {
            evaluation: [''],
            imgUrl: [],
            initScore: null,
            isType: 0,
            negative: 0,
            specialId: 0,
            totalScore: null,
            itemType: 0,
          };
          item.lightValue = item.lightValue?item.lightValue.split('|'):[];
          item.questionValue = item.questionValue?item.questionValue.split('|'):[];
          item.itemType = 0;
          item.imgUrl = item.imgUrl ? item.imgUrl.split(",") : [];
          obj.specialId = item.id;
          item["type0"] = JSON.parse(JSON.stringify(obj));
          if (item.parentId != this.form.specialId) {
            item["type1"] = [];
            item["type2"] = [];
          }

          // 分项问题点
          if (item.dataType==3) {
            item['type2'] = []
          }
          // 分项问题点

          
          for (var im of data.contents ? data.contents : []) {
            if (item.id == im.specialId) {
              var str = "type" + im.isType;
              im.imgUrl = im.imgUrl ? im.imgUrl.split(",") : [];
              im.evaluation = im.evaluation.split('%s%')
              if (im.isType == 0) {
                item[str] = im;
              } else {
                item[str].push(im);
              }
            }
          }
        }

        var list = this.GetTree(specials, this.form.specialId)[0].children;
        data.list = list;
        data.addTotalScore = null;
        data.reduceTotalScore = null;
        var type1Arr = [];
        var type2Arr = [];

        data.additions = data.additions ? data.additions : [];

        for (var item of data.additions) {
          item.imgUrl = item.imgUrl ? item.imgUrl.split(",") : [];
          item.evaluation = item.evaluation.split('%s%')
          if (item.isType == 1) {
            type1Arr.push(item);
          } else {
            type2Arr.push(item);
          }
        }

        data.additionsList = {
          type1: type1Arr,
          type2: type2Arr,
        };

        this.details = data;
      }).finally(()=>{
        this.$toast.clear()
      });
    },
    formData() {
      this.form = JSON.parse(localStorage.getItem("form"));
    },
    GetTree(data, id) {
      
      var list = data;
      var top = [],
        sub = [],
        tempObj = {};
      list.forEach(function (item) {
        if (item.id == id) {
          // 顶级分类
          item.index = top.length;
          top.push(item);
        } else {
          // item.index = sub.length
          sub.push(item); // 其他分类
        }
        item.children = []; // 默然添加children属性

        tempObj[item.id] = item; // 用当前分类的id做key，存储在tempObj中
      });
      sub.forEach(function (item) {
        // 取父级
        var parent = tempObj[item.parentId] || { children: [] };
        // 把当前分类加入到父级的children中
        item.index = parent.children.length;
        parent.children.push(item);
      });
      console.log(top);
      return top;
    },
    // 点击日期弹窗确认按钮
    dateTimeConfirm(value, index) {
      console.log(
        `当前值：${value}, 当前索引：${index}`,
        value.getFullYear(),
        value.getMonth(),
        value.getDate()
      );

      let date_str = `${value.getFullYear()} 年 ${
        value.getMonth() + 1
      } 月 ${value.getDate()} 日`;
      var m = value.getMonth() + 1;
      var d = value.getDate();
      this.details.acceptanceDate =
        value.getFullYear() +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " 00:00:00";
      // console.log("date_str", date_str);
      this.screen_date = date_str;
      this.screen_date_show = false;
    },
    // 格式化日期
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
   
  },
  mounted() {
    
  }
}
</script>
<style lang="scss" scoped>
.page-acceptance {
  min-height: 100vh;
  background-color: #f6f6f6;
  box-sizing: border-box;
  // padding-bottom: 60vh;
}

.tab-text {
  position: relative;
  padding: 14px 30px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 2px;
  }
}

.tab-active {
  .tab-text {
    color: #6396fe;
    &:before {
      background-color: #6396fe;
    }
  }
}
.screen-wrap {
  display: flex;
  align-items: center;
  min-width: calc((100% - 24px) / 3);
  max-width: calc((100% - 24px) / 3);
  padding: 6px 10px;
  border: 1px solid rgba(#000, 0.2);
  color: #666;
  font-size: 14px;
  line-height: 1;
  border-radius: 4px;
  box-sizing: border-box;
  & + .screen-wrap {
    margin-left: 12px;
  }
}
.empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 64px 20px;
  &-img {
    width: 214px;
  }
}

.huses-item {
  box-sizing: border-box;
  padding: 8px;
  background-color: #fff;
  border-radius: 5px;
  width: calc((100% - 8px) / 2);
  margin-bottom: 8px;
  margin-left: 8px;
  &:nth-child(2n + 1) {
    margin-left: 0;
  }
}
.houses-img-wrap {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.houses-img {
  object-fit: contain;
}
.icon-replay {
  width: 14px;
  height: 14px;
  background-image: url("../../assets/acceptance/replay.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.place-box {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  padding: 0 10px;
  margin: 1px 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.place-box .van-icon-arrow {
  line-height: 40px;
  margin: 0 7px;
}
.place-box .van-icon-arrow:nth-last-child(1) {
  display: none;
}
</style>
